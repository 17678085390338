<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn" >
    <template #tail>
        <ly-button @click="exportAdd" class="m-l1" :loading="false">
          导出
        </ly-button>
      </template>
     </ly-searchList> 
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" ref="tableRef" size="small" rowKey="id" :data="data" :loading="loading" :pagination="pagination" @pageChange="pageChange">
         <template #sort="{ index }">
          {{ index+1  }}
         </template>
         <template #driverName="{ record }">
          <div>{{ record.driverName }}</div>
          <div>{{ record.driverMobile  }}</div>
         </template>
         <template #startAddress="{ record }">
        {{ record.startAddress }}
        <div>{{ record.endAddress }}</div>
         </template>
      </ly-table>
    </div>

  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns } from './waybillReport'
import { statementTransPage, transExport } from '@/api/finance/income.js'
import { addressTran, transitionTimeYMD} from '@/utils/util.js'
import { cloneDeep } from 'lodash-es';

const searchMsg = ref({})
const data = ref([])
const loading = ref(false)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value =  e
  reqStatementTransPage()
}
 
//导出
const exportAdd = () => {
  transExport(searchMsg.value,'运单报表-导出').then(res => {
  })
}
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = cloneDeep(e) 
  searchMsg.value.startTime = transitionTimeYMD(e.startTime)
  searchMsg.value.endTime = transitionTimeYMD(e.endTime)
  reqStatementTransPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqStatementTransPage()
}

const reqStatementTransPage = () => {
  loading.value = true
  let msg = {
    current:pagination.value.current,
    size:pagination.value.pageSize
  }
  Object.assign(msg,searchMsg.value)
  statementTransPage(msg).then( res => {
    if( res.code !== 10000 ) return
    data.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  reqStatementTransPage()
})

  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style> 