import { ref } from 'vue'
export const iptData = ref([

  {
    type: 'select',
    placeholder: '结算状态',
    value: null,
    prop: 'settlementStatus',
    width: '10%',
    opt:[
      { label: '未结算', value: 0 },
      { label: '部分结算', value: 1 },
      { label: '已结算', value: 2 }
    ]
  },
  {
    type: 'input',
    placeholder: '运单编号',
    value: null,
    prop: 'transId',
    width: '12%',
  },
  {
    type: 'input',
    placeholder: '派车人',
    value: null,
    prop: 'dispatcher',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '运输线路起',
    value: null,
    prop: 'startAddress',
    width: '10%',
    changeOn:true
  },
  {
    type: 'input',
    placeholder: '运输线路止',
    value: null,
    prop: 'endAddress',
    width: '10%',
    changeOn:true
  },
  {
    type: 'selectTime',
    placeholder: '运输时间起',
    value: null,
    prop: 'startTime',
    width: '10%',
  },
  {
    type: 'selectTime',
    placeholder: '运输时间止',
    value: null,
    prop: 'endTime',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '司机姓名',
    value: null,
    prop: 'driverName',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '大车车牌',
    value: null,
    prop: 'truckNo',
    width: '10%',
  },
])
export const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots: { customRender: 'sort' },
    align: 'center',
    width: '5%'
  },
  {
    title: '运单编号',
    dataIndex: 'transId',
    align: 'center',
  },
  {
    title: '起运时间',
    dataIndex: 'departureTime',
    align: 'center',
  },
  {
    title: '客户',
    dataIndex: 'carrierName',
    align: 'center'
  },
  {
    title: '运输线路',
    dataIndex: 'startAddress',
    slots:{ customRender: 'startAddress' },
    align: 'center'
  },
  {
    title: '派车人',
    dataIndex: 'dispatcher',
    align: 'center'
  },
  {
    title: '大车类型',
    dataIndex: 'truckType.label',
    align: 'center'
  },
  {
    title: '车牌号/司机',
    dataIndex: 'transName',
    align: 'center'
  },
  {
    title: '司机信息',
    dataIndex: 'driverName',
    slots:{customRender:'driverName'},
    align: 'center'
  },
  {
    title: '到达时间',
    dataIndex: 'arrivalTime',
    align: 'center'
  },
  {
    title: '总可结算金额',
    dataIndex: 'settableTotalAmt',
    align: 'center'
  },
  {
    title: '总已结算金额',
    dataIndex: 'settledAmt',
    align: 'center'
  },
  {
    title: '油卡已结算金额',
    dataIndex: 'settledOilAmt',
    align: 'center'
  },
  {
    title: '转账已结算金额',
    dataIndex: 'settledCardAmt',
    align: 'center'
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center'
  },
])
